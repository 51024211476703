import React from "react";

const Footer = (props) => {
  var display;

  display = (
    <div className="p-2 mb-2 bg-dark text-white">
      <div className="row">
        <div className="col-md-5" />
        <div className="col-md-1">
          <a target="_blank" className="text-white" href="https://aperium.io">
            Home
          </a>
        </div>
        <div className="col-md-1">
          <a
            target="_blank"
            className="text-white"
            href="https://aperium.atlassian.net/servicedesk/customer/portal/2"
          >
            Support
          </a>
        </div>
      </div>
      <p></p>
      <div className="text-center">
        <small>Aperium 2019 - 2025 - all rights reserved</small>
      </div>
      <div className="text-right text-secondary">
        <small>release#1 20250402</small>
      </div>
    </div>
  );

  return display;
};

export default Footer;
